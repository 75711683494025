@charset "UTF-8";
.wrap_floatbtn {
  -webkit-animation: fuwafuwa 3s infinite linear alternate;
  animation: fuwafuwa 3s infinite linear alternate; }

@-webkit-keyframes fuwafuwa {
  0% {
    -webkit-transform: translate(0, 0); }
  50% {
    -webkit-transform: translate(0, -10px); }
  100% {
    -webkit-transform: translate(0, 0); } }

@keyframes fuwafuwa {
  0% {
    transform: translate(0, 0); }
  50% {
    transform: translate(0, -10px); }
  100% {
    transform: translate(0, 0); } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
追加分：ホバーアニメーション
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media only screen and (min-width: 767px) {
  .wrap_topics .inner_content .inner_topics .block_linkbtn .item_link:hover, .wrap_section .inner_content .block_cont .common_link a:hover, section.wrap_shop .inner_content .block_cont .block .item_box .item_right .link a:hover, .wrap_topbox05 .inner_content .block_cont .block_right .item_link .link:hover {
    background-color: #728a00;
    background-position: 95% 46%; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
追加分：スクロールを促すアニメーション（メインビジュアル）
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.css_scrollBottom {
  -webkit-animation: sdb 1.5s infinite;
  animation: sdb 1.5s infinite; }

@-webkit-keyframes sdb {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: translate(0px, 10px);
    opacity: 0; } }

@keyframes sdb {
  0% {
    transform: translate(0, 0);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    transform: translate(0px, 10px);
    opacity: 0; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
追加分：スクロールアニメーション
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.js_fadeIn01, .js_fadeIn02, .js_fadeIn03, .js_fadeIn04 {
  opacity: 0; }

.show.js_fadeIn01, .show.js_fadeIn02, .show.js_fadeIn03, .show.js_fadeIn04 {
  -webkit-animation-name: animation_fadeUp,animation_fadeIn;
  animation-name: animation_fadeUp,animation_fadeIn;
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94), cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94), cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

@-webkit-keyframes animation_fadeUp {
  0% {
    -webkit-transform: translate(0, 64px);
    transform: translate(0, 64px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes animation_fadeUp {
  0% {
    -webkit-transform: translate(0, 64px);
    -ms-transform: translate(0, 64px);
    transform: translate(0, 64px); }
  100% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0); } }

@-webkit-keyframes animation_fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes animation_fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@media only screen and (min-width: 767px) {
  .show .js_fadeIn01, .show .js_fadeIn02, .show .js_fadeIn03, .show .js_fadeIn04 {
    -webkit-animation-name: animation_fadeUp,animation_fadeIn;
    animation-name: animation_fadeUp,animation_fadeIn;
    -webkit-animation-duration: .8s;
    animation-duration: .8s;
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94), cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94), cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .show .js_fadeIn02 {
    animation-delay: .75s; }
  .show .js_fadeIn03 {
    animation-delay: 1.5s; }
  .show .js_fadeIn04 {
    animation-delay: 2.25s; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
共通
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media only screen and (min-width: 767px) {
  a {
    transition: all 0.3s ease; }
    a img {
      transition: all 0.3s ease; }
    a:hover {
      transition: all 0.3s ease; }
    a img {
      transition: all .3s ease; }
    a.no_link {
      pointer-events: none; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
ボタンホバー周り
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media only screen and (min-width: 767px) {
  .wrap_sect01 .inner_content .block_link a,
  .wrap_sect03 .inner_content .block_link a {
    border: solid 1px white; }
    .wrap_sect01 .inner_content .block_link a:hover,
    .wrap_sect03 .inner_content .block_link a:hover {
      background-color: white;
      border: solid 1px #004aab;
      color: #004aab; }
  #sect_footer .inner_content .block_contact .item_contact a {
    transition: all .6s ease; }
    #sect_footer .inner_content .block_contact .item_contact a:hover {
      background-color: #004aab;
      color: white;
      transition: all .6s ease; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
SPグローバルメニュー開閉式トグルメニュー（スマホ）
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.child-btn,
.child-btn span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box; }

.child-btn {
  position: relative; }

.child-btn span {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 50%;
  height: 2px;
  background-color: #db2e80;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px; }

.child-btn span:nth-of-type(1) {
  top: calc(50% - 2px);
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg); }

.child-btn span:nth-of-type(2) {
  top: calc(50% - 2px);
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg); }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
グローバルナビゲーション
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media only screen and (min-width: 767px) {
  header .wrap_header .inner_nav ul li a:after {
    transition: all 0.3s ease;
    transform: translate(0%, 3px); }
  header .wrap_header .inner_nav ul li a:not(.active):hover:after {
    opacity: 1;
    transition: all 0.3s ease;
    transform: translate(0%, 0px); } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
フロートアクション
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media only screen and (min-width: 767px) {
  header .wrap_header .inner_nav .block_contact:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15), 0 10px 30px 0 rgba(0, 0, 0, 0.1); } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
アニメーション：ページトップへ戻る
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#js_topBtn {
  transition: all 0.3s ease; }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
ギャラリー
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media only screen and (min-width: 767px) {
  .js_fadeInbox01 .block_col a,
  .js_fadeInbox02 .block_col a {
    display: block;
    overflow: hidden; }
    .js_fadeInbox01 .block_col a img,
    .js_fadeInbox02 .block_col a img {
      transition: all 0.6s ease; }
    .js_fadeInbox01 .block_col a:hover img,
    .js_fadeInbox02 .block_col a:hover img {
      transform: scale(1.15);
      transition: all 0.6s ease; } }
